<template>
  <base-section id="placeholder">
    <v-container>
      <div style="margin-bottom: 1.5em;">
        <p class="text-uppercase text-h6 font-weight-bold mb-5 text-left">
          Safe Harbor Marina is in the process of seeking permission to build a
          new marina facility at Union and Water&nbsp;Streets.
        </p>
        <p
          class="base-body text-body-1 mx-auto grey--text text--darken-1 text-left"
        >
          This project will make major alterations and additions to the existing
          marine use area which will impact our neighborhood, the Bradford
          Historic District, and the Plymouth Bay Cultural District.
        </p>
        <p
          class="base-body text-body-1 mx-auto grey--text text--darken-1 text-left"
        >
          There are numerous concerns about both the short- and long-term
          implications for our neighborhood and many unanswered questions.
        </p>
        <p class="text-uppercase text-h6 font-weight-bold mt-9 text-left">
          This is a website for our neighborhood, by our neighborhood.
        </p>
        <p
          class="base-body text-body-1 mx-auto grey--text text--darken-1 text-left"
        >
          In the coming weeks more information will be added regarding some of
          the potential implications of this project, including increased
          flooding, environmental impacts on surrounding wetlands, public access
          to the waterfront, and destruction of a historical structure. In the
          meantime, if you would like to be part of the conversation and connect
          with your neighbors, please
          <a href="mailto:BradfordAreaCommission@gmail.com">email us</a>.
        </p>
      </div>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "Placeholder",

  data: () => ({})
};
</script>

<style lang="scss">
#placeholder {
  padding: 2em 1em !important;
  img {
    width: 100%;
    max-width: 1000px;
    margin: 1em auto;
  }
  .container {
    max-width: 1000px;
  }
}
</style>
